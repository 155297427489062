import React, { useState, useEffect } from "react";
import { IconButton, Tooltip } from "@material-tailwind/react";
import { ViewIcon } from "../../utils/icons";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { tableHeaderStyles } from "../../utils/utils";
import axiosClient from "../../axios-client";
import Swal from "sweetalert2";
import { RemoveIcon } from "../../utils/icons";
import ViewContact from "./ViewContact";

const Contactus = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [viewContactOpen, setViewContactOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [nameSearchQuery, setNameSearchQuery] = useState("");

  // Fetching contacts from the backend
  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("/contact"); // Ensure the backend returns contact details
      setContacts(response.data);
    } catch (error) {
      toast.error("Failed to fetch contacts");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  // Handler for clicking view button
  const handleViewClick = (contact) => {
    setSelectedContactId(contact);
    handleViewContactOpen();
  };

  // Handler for closing the view contact dialog
  const handleViewContactOpen = () => {
    setViewContactOpen((cur) => !cur);
  };

  // Effect to filter contacts
  useEffect(() => {
    const filtered = contacts.filter((contact) => {
      const fullName = `${contact.FirstName} ${contact.LastName}`.toLowerCase();
      return fullName.includes(nameSearchQuery.toLowerCase());
    });

    setFilteredContacts(filtered);
  }, [nameSearchQuery, contacts]);

  // Creating the table
  const TABLE_CONTACT = [
    {
      name: "Name",
      selector: (row) => row.FullName,
      wrap: false,
      maxWidth: "auto",
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      wrap: false,
      minWidth: "200px",
    },
    {
      name: "Contact",
      selector: (row) => row.Contact,
      wrap: false,
      minWidth: "150px",
    },
    {
      name: "Type",
      selector: (row) => row.Type,
      wrap: false,
      minWidth: "150px",
    },
    {
      name: "Message",
      selector: (row) => row.Message,
      wrap: false,
      minWidth: "250px",
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Tooltip content="View Contact">
            <IconButton
              onClick={() => handleViewClick(row)}
              variant="text"
              className="mx-2 bg-white"
            >
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <section className="mt-8 md:block">
        <div className="w-full bg-white rounded-[15px] px-[30px] pt-[20px] pb-[20px] mt-10 relative overflow-auto">
          <DataTable
            columns={TABLE_CONTACT}
            responsive
            data={filteredContacts}
            customStyles={tableHeaderStyles}
            className="mt-4"
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationComponentOptions={{
              rowsPerPageText: "Entries per page:",
              rangeSeparatorText: "of",
            }}
            noDataComponent={
              <div className="text-center">No data available</div>
            }
            progressPending={loading}
          />
        </div>
      </section>

      <ToastContainer />
      {selectedContactId && (
        <ViewContact
          open={viewContactOpen}
          handleOpen={handleViewContactOpen} // Passing the close handler
          contact={selectedContactId} // Passing the selected contact
        />
      )}
    </>
  );
};

export default Contactus;
