import React from "react";
import { MantineProvider, createTheme } from "@mantine/core";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { ContextProvider } from "./contexts/NavigationContext";

import "./pages/pages.css";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import MainLayout from "./layouts/MainLayout";
import News from "./pages/News";
import NewsReadMore from "./pages/NewsReadMore";
import ContactUs from "./pages/ContactUs";
import { AdminLayout } from "./components/layouts/AdminLayout";
import { GuestLayout } from "./components/layouts/GuestLayout";
import Contactus from "./pages/contactus/Contactus";
import { Login } from "./pages/Login";

const theme = createTheme({
  theme: "light",
  fontFamily: "Inter",
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/news/:slug",
        element: <NewsReadMore />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <Contactus />,
      },
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
]);

function App() {
  return (
    <ContextProvider>
      <ThemeProvider>
        <MantineProvider theme={theme}>
          <RouterProvider router={router} />
        </MantineProvider>
      </ThemeProvider>
    </ContextProvider>
  );
}

export default App;
