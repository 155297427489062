import React from "react";
import { Card, Dialog } from "@material-tailwind/react";
import { CloseIcon } from "../../utils/icons";

export const ViewContact = ({ handleOpen, open, contact }) => {
  return (
    <>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none rounded-[10px] overflow-y-scroll scrollbar-hide overflow-x-hidden font-poppins"
      >
        <Card className="w-full p-5 mx-auto rounded-sm text-[#64728C]">
          <div className="flex justify-between mb-4 border-b align-center border-[#64728C] border-opacity-15">
            <div className="pb-3 text-lg font-medium font-poppins ">
              Contact Details
            </div>
            <div
              className="font-bold text-[20px] cursor-pointer"
              onClick={handleOpen} // Correctly calling the handler to close the modal
            >
              <CloseIcon />
            </div>
          </div>

          <div className="flex text-[14px]">
            <div className=" w-[50%]">
              <div className="font-semibold">Name</div>
              <div>{contact?.FullName}</div>
            </div>
            <div className=" w-[50%]">
              <div className="font-semibold">Email</div>
              <div>{contact?.Email}</div>
            </div>
          </div>

          <div className="flex mt-3 text-[14px]">
            <div className=" w-[50%]">
              <div className="font-semibold">Type</div>
              <div>{contact?.Type}</div>
            </div>
            <div className=" w-[50%]">
              <div className="font-semibold">Contact</div>
              <div>{contact?.Contact}</div>
            </div>
          </div>

          <div className="flex mt-3 text-[14px]">
            <div className=" w-[100%]">
              <div className="font-semibold">Message</div>
              <div>{contact?.Message}</div>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ViewContact;
