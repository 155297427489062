import { UsersIcon } from "./icons";

export const newNavigationItems = [
  {
    title: "Contact",
    link: "/",
    icon: UsersIcon,
    children: 0,
  },
];

export const branches = [
  {
    id: 1,
    key: "Klautara",
  },
  {
    id: 2,
    key: "Colombo",
  },
];

export const subPathLinks = {
  "New Leave Request": "/leave-request/add",
  "My Account": "/account",
};
